import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import Zoom from "react-medium-image-zoom"
import firebase from "gatsby-plugin-firebase"
import { Flex, Box, Text } from "@chakra-ui/core"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1, H3 } from "../components/Typography"
import ShareButtons from "../components/ShareButtons"

const settings = {
  dots: true,
  lazyLoad: "ondemand",
  adaptiveHeight: true,
}

const wrapperStyle = {
  width: "100%",
  height: "100%",
  margin: "auto",
}

export default function Template({ data }) {
  const { notice } = data
  const { title, description, image, fields } = notice || {}
  const { date, slug } = fields || {}
  useEffect(() => {
    firebase.analytics().setCurrentScreen(title)
  }, [])
  return (
    <Layout>
      <SEO title={title} />
      <Flex marginBottom={8} align="center" justify="center" textAlign="center">
        <H1>{title}</H1>
      </Flex>
      <Box mb={8}>
        <Slider {...settings}>
          <Zoom>
            <Flex
              align="center"
              justify="center"
              flexGrow="1"
              width="90vw"
              maxW="1120px"
            >
              <Img
                fluid={image.childImageSharp.fluid}
                alt="featuredImage"
                style={wrapperStyle}
              />
            </Flex>
          </Zoom>
        </Slider>
      </Box>
      <br />
      <H3>{date}</H3>
      <br />
      <div
        className="blog-post-content"
        style={{ whiteSpace: 'pre-wrap' }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Box mt={3} />
      <ShareButtons
        title={title}
        url={`https://www.laudiumtoday.co.za/${slug}`}
        twitterHandle={"LaudiumToday"}
        tags={["LaudiumToday", "news"]}
      />
      <Box mt={3} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    notice(id: { eq: $id }) {
      noticeCategories {
        name
      }
      description
      image {
        childImageSharp {
          fluid(maxHeight: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      id
      fields {
        date(formatString: "DD MMMM YYYY")
        slug
      }
      title
    }
  }
`
